import { Divider, LegacyStack, Page, VerticalStack } from '@shopify/polaris'
import { DateTime } from 'luxon'
import { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router'

import { Footer } from '../common/Footer'
import { useQuery, useShopFlag, useShopPermission, useShopTimeZone } from '../common/helpers'
import { ReferralCodeBanner } from '../common/ReferralCodeBanner'
import { FeatureAccessFlag } from '../common/SystemData'
import { TabIndex, Tabs } from '../common/Tabs'
import * as urls from '../common/urls'
import { SellingPlansContext, useSellingPlansSWR } from '../workflow-page/api'
import { BasicStats } from './BasicStats'
import {
  CustomerRententionAndValueStatsPlaceholder,
  CustomerRetentionAndValueStats,
} from './CustomerRetentionAndValueStats'
import { CalendarEventsType, PaymentSchedule } from './PaymentSchedule'
import { Period } from './PeriodSelector'
import { RecentActivity } from './RecentActivity'
import { RetentionCohortReport } from './RetentionCohortReport'

interface PageQuery {
  date: string
  period: Period
  period_start: string | null
  period_end: string | null
  event_types: string
}

const usePageQuery = (): PageQuery => {
  const query = useQuery()
  const timeZone = useShopTimeZone()
  const date: string = query.get('date') ?? DateTime.now().setZone(timeZone).toISODate()

  return useMemo(() => {
    const event_types = query.get('event_types') ?? 'payments,fulfillments'
    const period: Period = (query.get('period') ?? 'last_30_days') as Period
    const period_start: string | null = query.get('period_start')
    const period_end: string | null = query.get('period_end')

    return {
      date,
      period,
      period_start,
      period_end,
      event_types,
    }
  }, [query, date])
}

export const DashboardPage = () => {
  const pageQuery = usePageQuery()
  const history = useHistory()

  const enhancedReportsFlag = useShopFlag('enhanced_reports') as FeatureAccessFlag
  const enhancedReportsEnabled = useShopPermission('enhancedReportsEnabled')
  const showEnhancedReports =
    enhancedReportsFlag === 'enabled' ||
    (enhancedReportsEnabled && enhancedReportsFlag === 'by-plan')

  const sellingPlansSWR = useSellingPlansSWR()

  const updateParams = useCallback(
    (newParams: Partial<PageQuery>) => {
      history.replace(urls.dashboardUrl({ ...pageQuery, ...newParams }))
    },
    [pageQuery, history]
  )

  const setPeriod = useCallback(
    (period: Period, period_start?: string, period_end?: string) =>
      updateParams({ period: period, period_start: period_start, period_end: period_end }),
    [updateParams]
  )

  const setDate = useCallback((date: string) => updateParams({ date: date }), [updateParams])
  const setEventTypes = useCallback(
    (eventTypes: CalendarEventsType[]) => updateParams({ event_types: eventTypes.join(',') }),
    [updateParams]
  )

  return (
    <SellingPlansContext.Provider value={sellingPlansSWR.data ?? []}>
      <Tabs selected={TabIndex.Dashboard} />
      <ReferralCodeBanner />
      <Page title="Dashboard" secondaryActions={[{ content: "What's New", url: urls.newsUrl() }]}>
        <LegacyStack vertical spacing="extraLoose">
          <BasicStats />
          <Divider />
          <PaymentSchedule
            date={pageQuery.date}
            setDate={setDate}
            eventTypes={pageQuery.event_types.split(',').filter(Boolean) as CalendarEventsType[]}
            setEventTypes={setEventTypes}
          />
          <Divider />
          <RecentActivity
            period={pageQuery.period}
            periodStart={pageQuery.period_start}
            periodEnd={pageQuery.period_end}
            setPeriod={setPeriod}
            showEnhancedReports={showEnhancedReports}
          />
          <Divider />
          {showEnhancedReports && <CustomerRetentionAndValueStats />}
          {!showEnhancedReports && (
            <VerticalStack gap="4">
              <RetentionCohortReport filterQuery="" filterLabel="" />
              <Divider />
              <CustomerRententionAndValueStatsPlaceholder />
            </VerticalStack>
          )}
          <Footer />
        </LegacyStack>
      </Page>
    </SellingPlansContext.Provider>
  )
}
