import {
  Button,
  ButtonGroup,
  Image,
  Layout,
  LegacyCard,
  LegacyStack,
  Link,
  LinkAction,
  List,
  Page,
  Text,
  VerticalStack,
} from '@shopify/polaris'

import { Footer } from '../common/Footer'
import { InternalLink, showIntercom, ShowIntercomLink, useQuery } from '../common/helpers'
import { TabIndex, Tabs } from '../common/Tabs'
import * as urls from '../common/urls'
import styles from './NewsPage.module.css'

export const NewsPage = () => {
  const query = useQuery()
  const returnUrl = query.get('returnUrl') ?? urls.dashboardUrl()
  const backAction: LinkAction = { url: returnUrl }

  return (
    <>
      <Tabs selected={TabIndex.Dashboard} />
      <Page title="What's New" backAction={backAction}>
        <Layout>
          <Layout.Section>
            <div className={styles.cardWrapper}>
              <LegacyCard sectioned>
                <LegacyStack alignment="center" wrap={false}>
                  <LegacyStack.Item fill>
                    <LegacyStack vertical spacing="loose">
                      <p>
                        PayWhirl developers continually work on new features and improvements. If
                        you have any feedback or suggestions, please don't hesitate to{' '}
                        <ShowIntercomLink>get in touch</ShowIntercomLink>!
                      </p>
                      <LegacyStack vertical spacing="tight">
                        <p>
                          <Text as="span" fontWeight="semibold">
                            Coming up soon:
                          </Text>
                        </p>
                        <List>
                          <List.Item>
                            New dashboard stats for LTV, Subscription Duration and Churn
                          </List.Item>
                          <List.Item>New plan selector designs</List.Item>
                        </List>
                      </LegacyStack>
                      <ButtonGroup>
                        <Button onClick={showIntercom}>Suggest a new feature</Button>
                      </ButtonGroup>
                    </LegacyStack>
                  </LegacyStack.Item>
                  <Image source="/images/programming.svg" className={styles.img} alt="" />
                </LegacyStack>
              </LegacyCard>
            </div>
          </Layout.Section>
          <Layout.Section>
            <LegacyCard sectioned>
              <VerticalStack gap="3">
                <Text variant="headingMd" as="h2">
                  February 10, 2024
                </Text>
                <List>
                  <List.Item>
                    Added fulfillment orders to the payment schedule calendar on the{' '}
                    <Link url={urls.dashboardUrl()}>dashboard</Link> page.
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  September 10, 2024
                </Text>
                <List>
                  <List.Item>
                    Added support for customers to reactivate their subscription directly in the
                    customer portal.
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  August 26, 2024
                </Text>
                <List>
                  <List.Item>
                    Added customer retention and value metrics with advanced filters to the{' '}
                    <Link url={urls.dashboardUrl()}>dashboard</Link> page.
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  August 20, 2024
                </Text>
                <List>
                  <List.Item>
                    Added support for merchants to reactivate finished subscriptions.
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  July 12, 2024
                </Text>
                <List>
                  <List.Item>
                    Added <Link url={urls.settingsUrl()}>failed payment workflows</Link> that allow
                    skipping orders or pausing subscriptions after the last failed payment attempt.
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  June 12, 2024
                </Text>
                <List>
                  <List.Item>
                    Added 'Product SKU' filter and ability to filter subscriptions with removed
                    product variants to{' '}
                    <Link url={urls.subscriptionsUrl()}>subscription listing</Link>
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  May 10, 2024
                </Text>
                <List>
                  <List.Item>
                    Added custom date range selection to{' '}
                    <Link url={urls.dashboardUrl()}>dashboard</Link> reports
                  </List.Item>
                  <List.Item>
                    Added 'subscription initial order number' tag variable to{' '}
                    <Link url={urls.workflowsUrl}>workflows</Link>
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  May 6, 2024
                </Text>
                <List>
                  <List.Item>
                    Added 'Payment retries' column and payment retries advanced filters to{' '}
                    <Link url={urls.subscriptionsUrl()}>subscription listing</Link>
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  April 4, 2024
                </Text>
                <List>
                  <List.Item>
                    Added 'Paused' column to{' '}
                    <Link url={urls.subscriptionsUrl()}>subscription listing</Link> and subscription
                    exports
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  March 18, 2024
                </Text>
                <List>
                  <List.Item>
                    Added support for merchants to add products for a limited number of cycles{' '}
                  </List.Item>
                  <List.Item>
                    Added an option to the PayWhirl Plan Selector app block to show selling plan
                    group prices
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  March 4, 2024
                </Text>
                <List>
                  <List.Item>
                    Added 'payment_method' and 'payment_error_message' variables to{' '}
                    <Link url={urls.settingsUrl()}>email templates</Link>
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  February 29, 2024
                </Text>
                <List>
                  <List.Item>
                    Added <Link url={urls.workflowsUrl}>Subscription automation</Link> workflows
                  </List.Item>
                  <List.Item>
                    Added new actions to <Link url={urls.workflowsUrl}>workflows</Link> for adding
                    discounts, and adding or removing products (one-time or recurring)
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  February 28, 2024
                </Text>
                <List>
                  <List.Item>
                    Added 'Retrying payment' filter to{' '}
                    <Link url={urls.subscriptionsUrl()}>subscription listing</Link>
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  February 27, 2024
                </Text>
                <List>
                  <List.Item>
                    Added <Link url={urls.settingsUrl()}>Klaviyo integration</Link>
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  February 19, 2024
                </Text>
                <List>
                  <List.Item>
                    Added failed payments auto-retry right after the payment method is updated
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  January 24, 2024
                </Text>
                <List>
                  <List.Item>Added 'Skip order' to subscription bulk actions</List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  December 13, 2023
                </Text>
                <List>
                  <List.Item>
                    Added support for <Link url={urls.settingsUrl()}>inventory management</Link>
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  November 21, 2023
                </Text>
                <List>
                  <List.Item>
                    Added support for managing selling plans through the{' '}
                    <Link url={'https://api.shop.paywhirl.com/2022-04'} external>
                      API
                    </Link>
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  September 20, 2023
                </Text>
                <List>
                  <List.Item>
                    Added detailed execution logs to <Link url={urls.workflowsUrl}>workflows</Link>
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  April 7, 2023
                </Text>
                <List>
                  <List.Item>
                    Added an option to the PayWhirl Plan Selector app block to allow displaying
                    selling plans before one-time purchase option
                  </List.Item>
                  <List.Item>
                    Added support for changing order of{' '}
                    <InternalLink url={urls.plansUrl}>selling plans</InternalLink>
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  March 22, 2023
                </Text>
                <List>
                  <List.Item>Launched a brand new theme for the selling plan selector</List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  March 16, 2023
                </Text>
                <List>
                  <List.Item>
                    Added annual subscription reminder email to{' '}
                    <Link url={urls.settingsUrl()}>customer notifications</Link>
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  March 6, 2023
                </Text>
                <List>
                  <List.Item>Added customer and merchant notes to subscriptions</List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  February 24, 2023
                </Text>
                <List>
                  <List.Item>
                    Improved UI following Shopify's{' '}
                    <Link external url="https://shopify.dev/docs/apps/design-guidelines">
                      App Design Guidelines
                    </Link>
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  January 24, 2023
                </Text>
                <List>
                  <List.Item>
                    Launched support for{' '}
                    <Link url={urls.settingsUrl()}>
                      adding products to an existing subscription
                    </Link>{' '}
                    directly from the product page
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  December 8, 2022
                </Text>
                <List>
                  <List.Item>
                    Launched support for{' '}
                    <Link
                      external
                      url="https://docs.paywhirl.com/PayWhirl/en/articles/4731397-how-local-pickup-and-delivery-work-for-subscriptions-with-paywhirl"
                    >
                      local delivery & pickup
                    </Link>
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  September 28, 2022
                </Text>
                <List>
                  <List.Item>
                    Launched a <Link url={urls.settingsUrl()}>brand new theme</Link> for the
                    customer portal
                  </List.Item>
                  <List.Item>
                    Added support for installing the customer portal as an{' '}
                    <Link url={urls.customerPortalInstallUrl}>app block</Link>, on both 2.0 and
                    legacy themes
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  September 5, 2022
                </Text>
                <List>
                  <List.Item>
                    Added support for creating and activating subscriptions through the{' '}
                    <Link url={'https://api.shop.paywhirl.com/2022-04'} external>
                      API
                    </Link>
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  August 7, 2022
                </Text>
                <List>
                  <List.Item>
                    Added timeline view for changes made to{' '}
                    <InternalLink url={urls.plansUrl}>selling plans</InternalLink>
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  July 20, 2022
                </Text>
                <List>
                  <List.Item>
                    Added support for customer{' '}
                    <Link url={urls.settingsUrl()}>cancellation flows</Link>: offering to skip the
                    next order, pause or edit the subscription or apply a discount; asking for the
                    cancellation reason and feedback
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  July 6, 2022
                </Text>
                <List>
                  <List.Item>
                    Launched support for{' '}
                    <Link url={urls.subscriptionsUrl()}>editing subscriptions in bulk</Link> for all
                    merchants
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  June 29, 2022
                </Text>
                <List>
                  <List.Item>
                    Added more options for <Link url={urls.subscriptionsUrl()}>exporting</Link>{' '}
                    subscriptions
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  June 16, 2022
                </Text>
                <List>
                  <List.Item>
                    Improved filtering and sorting on the{' '}
                    <Link url={urls.subscriptionsUrl()}>subscriptions</Link> page
                  </List.Item>
                  <List.Item>
                    Subscription bulk actions are now available for beta testing
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  June 1, 2022
                </Text>
                <List>
                  <List.Item>
                    Added support for installing the selling plan selector as an{' '}
                    <Link
                      external
                      url="https://docs.paywhirl.com/en/articles/4554322-getting-started-with-the-paywhirl-subscription-app-for-shopify-2020-version#4-integrate-with-your-existing-theme-or-install-a-new-one"
                    >
                      app block
                    </Link>{' '}
                    in Online Store 2.0 themes
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  May 30, 2022
                </Text>
                <List>
                  <List.Item>
                    The <Link url={urls.settingsUrl()}>API and webhooks</Link> have been launched
                    for all merchants on paid pricing plans
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  April 19, 2022
                </Text>
                <List>
                  <List.Item>
                    The <Link url={urls.settingsUrl()}>API and webhooks</Link> are now available on
                    paid plans for beta testing
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  March 1, 2022
                </Text>
                <List>
                  <List.Item>
                    Launched support for{' '}
                    <Link
                      external
                      url="https://docs.paywhirl.com/PayWhirl/en/articles/4887610-how-do-shopify-customers-manage-their-subscriptions-2020-version"
                    >
                      editing subscriptions by customers
                    </Link>
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  February 3, 2022
                </Text>
                <List>
                  <List.Item>
                    Launched support for Apple Pay for merchants using Shopify Payments in the US,
                    Canada, Australia, and New Zealand for Visa and Mastercard.
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  February 2, 2022
                </Text>
                <List>
                  <List.Item>
                    Added timeline view to{' '}
                    <InternalLink url={urls.subscriptionsUrl()}>subscriptions</InternalLink>
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  January 26, 2022
                </Text>
                <List>
                  <List.Item>
                    Support for{' '}
                    <Link
                      external
                      url={
                        'https://docs.paywhirl.com/PayWhirl/en/articles/4887610-how-do-shopify-customers-manage-their-subscriptions-2020-version'
                      }
                    >
                      editing subscriptions by customers
                    </Link>{' '}
                    is now available for beta testing
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  December 22, 2021
                </Text>
                <List>
                  <List.Item>
                    New integration with{' '}
                    <Link
                      external
                      url={
                        'https://apps.shopify.com/customerhub?utm_source=paywhirl&utm_medium=referral'
                      }
                    >
                      CustomerHub
                    </Link>
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  November 5, 2021
                </Text>
                <List>
                  <List.Item>
                    Launched support for{' '}
                    <Link
                      external
                      url={
                        'https://blog.paywhirl.com/news/accelerate-subscription-checkouts-on-shopify-with-shop-pay/'
                      }
                    >
                      Shop Pay
                    </Link>
                  </List.Item>
                  <List.Item>Improved responsiveness of the customer portal</List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  September 28, 2021
                </Text>
                <List>
                  <List.Item>
                    Added support for <Link url={urls.workflowsUrl}>tagging</Link> customers and
                    orders
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  September 3, 2021
                </Text>
                <List>
                  <List.Item>
                    Improved compatibility with the{' '}
                    <Link external url={'https://themes.shopify.com/themes/dawn'}>
                      Dawn
                    </Link>{' '}
                    theme
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  August 17, 2021
                </Text>
                <List>
                  <List.Item>
                    Added support for setting anchor days in{' '}
                    <InternalLink url={urls.plansUrl}>selling plans</InternalLink>
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  June 30, 2021
                </Text>
                <List>
                  <List.Item>
                    Added support for viewing and applying discount codes to{' '}
                    <InternalLink url={urls.subscriptionsUrl()}>subscriptions</InternalLink>
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  June 16, 2021
                </Text>
                <List>
                  <List.Item>
                    Added support for sending{' '}
                    <InternalLink url={urls.settingsUrl()}>reminders</InternalLink> about upcoming
                    payments
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  June 14, 2021
                </Text>
                <List>
                  <List.Item>Launched support for pre-paid subscriptions</List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  June 03, 2021
                </Text>
                <List>
                  <List.Item>
                    Added advanced setting to{' '}
                    <InternalLink url={urls.plansUrl}>selling plans</InternalLink> to finish
                    subscriptions at the end of their billing cycle
                  </List.Item>
                  <List.Item>Improved UI of subscription listing and details pages</List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  May 04, 2021
                </Text>
                <List>
                  <List.Item>
                    Launched a new <InternalLink url={urls.dashboardUrl()}>dashboard</InternalLink>{' '}
                    page, with recent activity charts and payment calendar
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  April 29, 2021
                </Text>
                <List>
                  <List.Item>
                    Added support for{' '}
                    <InternalLink url={urls.subscriptionsUrl()}>searching</InternalLink>{' '}
                    subscriptions
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  April 14, 2021
                </Text>
                <List>
                  <List.Item>
                    Improved design and performance of{' '}
                    <InternalLink url={urls.plansUrl}>selling plan</InternalLink> pages
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  April 9, 2021
                </Text>
                <List>
                  <List.Item>
                    Added support for{' '}
                    <InternalLink url={urls.subscriptionsUrl()}>exporting</InternalLink>{' '}
                    subscription data
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  March 23, 2021
                </Text>
                <List>
                  <List.Item>
                    Implemented{' '}
                    <InternalLink url={urls.settingsUrl()}>system notifications</InternalLink> about
                    subscription events
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  March 17, 2021
                </Text>
                <List>
                  <List.Item>
                    Added built-in page for adding products to{' '}
                    <InternalLink url={urls.plansUrl}>selling plans</InternalLink> in bulk
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  February 23, 2021
                </Text>
                <List>
                  <List.Item>
                    Added support for creating subscriptions{' '}
                    <InternalLink url={urls.subscriptionCreateUrl}>manually</InternalLink>
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  February 10, 2021
                </Text>
                <List>
                  <List.Item>
                    Improved look and feel of the app to match the new design of Shopify admin
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  February 08, 2021
                </Text>
                <List>
                  <List.Item>
                    Added support for editing line items and shipping price in{' '}
                    <InternalLink url={urls.subscriptionsUrl()}>subscriptions</InternalLink>
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  January 19, 2021
                </Text>
                <List>
                  <List.Item>
                    Added support for editing payment schedule of existing{' '}
                    <InternalLink url={urls.subscriptionsUrl()}>subscriptions</InternalLink>
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  January 07, 2021
                </Text>
                <List>
                  <List.Item>
                    New integration with{' '}
                    <Link external url={'https://pagefly.io?ref=paywhirl&target=app-listing'}>
                      PageFly Landing Page Builder
                    </Link>
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  December 13, 2020
                </Text>
                <List>
                  <List.Item>
                    New integration with{' '}
                    <Link
                      external
                      url={
                        'https://apps.shopify.com/upsell-wizard?utm_source=paywhirl&utm_medium=referral'
                      }
                    >
                      Upsell Wizard
                    </Link>
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  December 02, 2020
                </Text>
                <List>
                  <List.Item>
                    Added support for customizable{' '}
                    <InternalLink url={urls.settingsUrl()}>email notifications</InternalLink>
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  November 18, 2020
                </Text>
                <List>
                  <List.Item>
                    Released version 2.0 of our{' '}
                    <InternalLink url={urls.planSelectorInstallUrl}>Liquid snippets</InternalLink>,
                    with support for featured products and improved compatibility with different
                    themes
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  November 12, 2020
                </Text>
                <List>
                  <List.Item>
                    Added support for{' '}
                    <InternalLink url={urls.translationsUrl()}>translating</InternalLink> the
                    customer portal
                  </List.Item>
                </List>
                <Text variant="headingMd" as="h2">
                  November 02, 2020
                </Text>
                <List>
                  <List.Item>Launched PayWhirl For Shopify</List.Item>
                </List>
              </VerticalStack>
            </LegacyCard>{' '}
          </Layout.Section>
          <Layout.Section>
            <Footer />
          </Layout.Section>
        </Layout>
      </Page>
    </>
  )
}
